import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { termsData } from '../data/terms-db';
import Layout from '../components/layout';

export default function TermsAndConditions() {
  return (
    <Layout>
      <section style={{ marginTop: '100px' }}>
        <Container>
          <h2 className="text-center">{termsData[0].heading}</h2>
          {termsData[0].data.map(({ id, heading, data }) => (
            <Row>
              <Col>
                <h4>
                  {id}
                  .
                  {heading}
                </h4>
                <p>{data}</p>
              </Col>
            </Row>
          ))}
        </Container>
      </section>
    </Layout>
  );
}
